<template>
  <v-app class>
    <div>
     <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
      <div class="col-md-12 ml-auto mr-auto">
        <v-container>
          <div class="mb-8 mt-8 text-center">
            <h1>Pending Requests
            </h1>
          </div>
          <DataTable :headers="headers" :multiSelect="true" :items="pendingRequests" :approve="approve" :approveBulk="approveBulk" :rejectFunc="reject" />
        </v-container>
      </div>
    </div>
  </v-app>
</template>
<script>

import DataTable from '@/components/ui/TableData.vue'
import {
  APPROVE_REQUEST,
  APPROVE_BULK_REQUEST,
  FETCH_PENDING_REQUEST
} from '@/store/action-type'

export default {
  components: {
    DataTable
  },
  data () {
    return {
      alertMessage: '',
      alert: false,
      alertType: '',
      headers: [
        {
          text: 'Name',
          value: 'employee.firstName'
        },
        {
          text: 'ChargeCode',
          value: 'employee.chargeCode'
        },
        {
          text: 'Request Type',
          value: 'requestType.name'
        },
        {
          text: 'Salary Peroid',
          value: 'salaryPeriod.description'
        },
        {
          text: 'Start Date',
          value: 'startDate'
        },
        // {
        //   text: 'Resumption Date',
        //   value: 'resumptionDate'
        // },
        {
          text: 'Added By',
          value: 'addedBy'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    pendingRequests () {
      return this.$store.getters.pendingRequests
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => {
        this.alert = false
      }, 5000)
    },
    approve (item) {
      item.status = 1

      this.$store
        .dispatch(APPROVE_REQUEST, item)
        .then((result) => {
          this.showAlertMessage('Approve Request successful', 'success')
          // eslint-disable-next-line handle-callback-err
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Approve Request failed', 'error')
        })
    },
    approveBulk (item) {
      item.forEach(element => {
        element.status = 1
      })

      this.$store.dispatch(APPROVE_BULK_REQUEST, item).then((result) => {
        this.showAlertMessage('Approve Requests successful', 'success')
        // eslint-disable-next-line handle-callback-err
      })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Approve Requests failed', 'error')
        })
    },
    reject (item) {
      item.status = 2
      this.$store
        .dispatch(APPROVE_REQUEST, item)
        .then((result) => {
          this.showAlertMessage('Reject Salary successful', 'success')
          // eslint-disable-next-line handle-callback-err
        })
        .catch((error) => {
          console.log(error)
          this.showAlertMessage('Reject Salary failed', 'error')
        })
    }
  },
  mounted () {
    this.$store
      .dispatch(FETCH_PENDING_REQUEST)
      .then(() => {})
      .catch((error) => {
        console.log(error)
        if (error) {
          this.showAlertMessage('There are no items pending your approval', 'warning')
        }
      })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
