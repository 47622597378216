<template>
  <div>
    <v-data-table
    :headers="headers"
    :items="items ? items : []"
    show-select
    v-model="selected"
  >

  <template v-if="multiSelect" v-slot:top>
     <div class="pa-5 d-flex">
       <v-btn class="ma-2" :disabled="selected.length <1" outlined color="indigo" @click.prevent="approveBulkSelection" >Bulk Approve</v-btn>
     </div>

    </template>

    <template v-slot:item.date="{ item }" >
                  {{item.date | formatDate}}
      </template>

    <template v-slot:item.actions="{ item }">
      <div class="actionBtn">
        <v-btn small class="blue mr-4" @click.prevent="approve(item)">Approve</v-btn>
        <v-btn small class="red mr-4" @click.prevent="openDialog(item)">Reject</v-btn>
        <v-btn small class="blue" @click.prevent="approve(item)">Return</v-btn>
      </div>
    </template>
  </v-data-table>
   <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline text-center"><h3>Reason for rejecting</h3></v-card-title>
        <v-divider class="mx-4 mb-5"></v-divider>
        <v-card-text class="mt-5">
          <v-form ref="commentForm" v-model="valid">
            <TextField  v-model="formData.comments" :label="'Comment'"/>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <Button
            :label="'Reject'"
            :btnType="'Submit'"
            @onClick="reject"
            :disabled="!valid"
            class="mr-4"
          />
          <v-btn color="green darken-1" text @click="closeModal">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import TextField from './form/TextField'
import Button from './form/Button'
export default {
  components: {
    TextField,
    Button
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    date: {
      type: String
    },
    headers: {
      type: Array,
      required: true
    },
    approve: {
      type: Function,
      required: true
    },
    rejectFunc: {
      type: Function
    },
    keyIdentifier: {
      type: String
    },
    approveBulk: {
      type: Function
    },
    multiSelect: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formData: {
        comments: '',
        id: ''
      },
      valid: false,
      dialog: false,
      singleSelect: false,
      selected: []
    }
  },
  methods: {
    openDialog (item) {
      this.dialog = true
      this.formData.id = item.id
    },
    closeModal () {
      this.dialog = false
      this.reset()
    },
    reject () {
      this.rejectFunc(this.formData)
      this.dialog = false
    },
    approveBulkSelection () {
      this.approveBulk(this.selected)
      this.selected = []
    },
    reset () {
      this.formData.id = ''
      this.$refs.commentForm.reset()
    }
  }
}
</script>
<style scoped>
.actionBtn {
  display: flex;
  align-items: center;
}
.container {
  padding: 0 !important;
}
</style>
